import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import {
  originalNameToName,
  originalNameToURL,
} from "../../helperFunctions/originalNameToName"

/*
Represents the linkboxes on main pages like /badehoteller
@props: {
  image: gatsby queried image;
  text: string;
}
*/

const LinkBox = ({ image, text }) => {
  return (
    <article className="w-full bg-white shadow-md rounded">
      <Image fluid={image} className="min-w-full h-56 rounded-t" />
      <Link
        to={originalNameToURL(text)}
        className="bg-white text-center p-md block font-semibold rounded hover:bg-darkSand duration-300"
      >
        {originalNameToName(text)}
      </Link>
    </article>
  )
}

export default LinkBox
