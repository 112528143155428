import React from "react"
import LinkBox from "./LinkBox"

/*
@props: {
  list: {
    image: queried gatsby image
  }[]
}
*/

const LinkBoxList = ({ list }) => {
  const renderLinkBoxes = list.map((box, index) => {
    return (
      <LinkBox
        image={box.childImageSharp.fluid}
        text={box.childImageSharp.fluid.originalName}
        key={index}
      />
    )
  })
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-lg container my-xl">
      {renderLinkBoxes}
    </section>
  )
}

export default LinkBoxList
