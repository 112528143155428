/* 
This function is used to take an image name, and convert it to a word with spacing and a capital letter
Example: 
bed-and-breakfast.jpg = Bed and breakfast

@param {string} image - image file name.
*/

export const originalNameToName = image => {
  return image
    .replace(image[0], image[0].toUpperCase())
    .split("-")
    .join(" ")
    .replace(".jpg", "")
}

/* 
This function is used to take an image name, and convert it into an URL.
Example: 
badehoteller.jpg = /badehoteller

@param {string} image - image file name.
*/
export const originalNameToURL = image => {
  return `/${image.replace(".jpg", "")}/`
}
