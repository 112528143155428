import React from "react"
import Image from "gatsby-image"

/*
display a image in "cover style". homepage, landing page, etc has these in the top of the page

@props: {
  title: string;
  image: gatsby queried image
}
*/

const CoverImage = ({ image, title }) => {
  return (
    <section className="relative h-72 flex items-center justify-center">
      <Image
        fluid={image}
        className="h-full absolute top-0 w-full"
        style={{ filter: "brightness(90%)" }}
      />
      <h1 className="text-white text-shadow-xl absolute text-center">
        {title}
      </h1>
    </section>
  )
}

export default CoverImage
