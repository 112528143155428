import React from "react"

const TextContent = ({ content }) => {
  return (
    <div className="container my-xl">
      <section
        dangerouslySetInnerHTML={{ __html: `${content}` }}
        className="text-content"
      ></section>
    </div>
  )
}

export default TextContent
