import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CoverImage from "../components/global/CoverImage"
import Layout from "../components/global/Layout"
import LinkBoxList from "../components/global/LinkBoxList"
import TextContent from "../components/TextContent"

const Index = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "covers/udsigt-baenk-cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          relativePath: {
            in: [
              "frontpage/badehoteller.jpg"
              "frontpage/bed-and-breakfast.jpg"
              "frontpage/golfophold.jpg"
              "frontpage/gourmetophold.jpg"
              "frontpage/hotelophold.jpg"
              "frontpage/kroophold.jpg"
              "frontpage/kurophold.jpg"
              "frontpage/slotsophold.jpg"
              "frontpage/weekendophold.jpg"
              "frontpage/wellness-spaophold.jpg"
            ]
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <Layout
      metaTitle="Opholdsguide.dk → Velkommen til en verden af ophold"
      metaDescription="Find inspiration i en af vores mange guides!"
      slug="/"
    >
      <CoverImage
        image={data.file.childImageSharp.fluid}
        title="Opholdsguide"
      />

      {<LinkBoxList list={data.allFile.nodes} />}
      <TextContent
        content=" <h2>Få gode råd og opdag verden i vores artikler</h2><p>
    Hos Opholdsguide går vi meget op i at finde de nyeste trends
    indenfor ophold og rejse generelt. Derudover er det vigtigt for os
    at vise verden og inspirere. Derfor kan du forvente at finde et hav
    af forskellige artikler, som ikke kun anmelder hoteller og
    restauranter, men også forskellige seværdigheder. Vi håber, at du
    vil læse med!
  </p>"
      />
    </Layout>
  )
}

export default Index
